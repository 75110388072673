.uk {
  &.front-page {
    .right-container {
      .advertisement {
        min-width: 300px;
      }
    }
  }
  &.entry.video {
    .entry__video-container {
      .cli-embed {
        .connatix-wrapper {
          .connatix-thumbnail {
            width: 100%;
          }
          .connatix-placeholder {
            padding-bottom: 56.12%;
          }
        }
      }
    }
  }
}
